<template>
<div class="content-wrapper">
  <div class="login">
    <component v-bind:is="loginComponent" />
  </div>
  </div>
</template>

<script>

import LoginFake from '../components/login/loginFake.vue';
import LoginReal from '../components/login/loginReal.vue';


const loginType = process.env.VUE_APP_LOGIN_PAGE;

export default {
  name: 'Login',
  components: {
    LoginFake,
    LoginReal,
  },
  computed: {
    loginComponent() {
      if (loginType === 'LoginFake') {
        return LoginFake;
      }
      return LoginReal;
    },
  },
  created() {
    this.$analytics.trackPageView();
  },
};
</script>

<style lang="scss" scoped>
.login {
  margin-top: 15px;
}
</style>
