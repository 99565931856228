<template>
  <div class="loginForm">
    <form @submit.prevent="submit">
      <TextBox v-if="haveStarsData"
               :label="'StarsId'"
               :type="'textbox'"
               :name="'starsId'"
               :disabled="true"
               v-model="userObject.starsId"
               class="textBox" />
      <TextBox :label="'First Name'"
               :name="'nameFirst'"
               :disabled="haveStarsData"
               v-model="userObject.nameFirst"
               class="textBox" />
      <TextBox :label="'Last Name'"
               :name="'nameLast'"
               :disabled="haveStarsData"
               v-model="userObject.nameLast"
               class="textBox" />
      <TextBox :label="'Preferred Name'"
               :name="'namePreferred'"
               :required="true"
               v-model="userObject.namePreferred"
               class="textBox" />
      <TextBox v-if="!haveStarsData"
               :label="'Position'"
               :name="'position'"
               :required="true"
               v-model="userObject.nonStarsPosition"
               class="textBox" />
      <TextBox v-if="!haveStarsData"
               :label="'Location'"
               :name="'location'"
               :required="true"
               v-model="userObject.location"
               class="textBox" />
      <TextBox :label="'Email'"
               :type="'email'"
               :name="'email'"
               :required="true"
               v-model="userObject.email"
               :validationFunction="isValidEmail"
               class="textBox" />
      <TextBox :label="'Phone'"
               :type="'tel'"
               :name="'phone'"
               v-model="userObject.phone"
               class="textBox" />
      <ButtonSubmit
        class="buttonOffset"
        :copy="'Send Now'"
        @callback="submit"
      />
    </form>
  </div>
</template>

<script>

import TextBox from '../formElements/TextBox.vue';
import ButtonSubmit from '../formElements/buttons/buttonSubmit.vue';

export default {
  name: 'LoginForm',
  props: ['stars', 'wslx'],
  components: {
    TextBox,
    ButtonSubmit,
  },
  created() {
    this.userObject.namePreferred = this.defaultPreferredName;
  },
  data() {
    return {
      userObject: {
        nameFirst: this.stars !== null ? this.stars.nameFirst : '',
        nameLast: this.stars !== null ? this.stars.nameLast : '',
        roleCode: this.stars !== null ? this.stars.roleCode : '',
        starsId: this.stars !== null ? this.stars.starsID : '',
        namePreferred: this.wslx.cdsId,
        wslxCdsid: this.wslx.cdsId,
        paCode: this.wslx.paCode,
        corporate: this.wslx.cdsId.includes('-') ? 0 : 1,
        email: '',
        phone: '',
        dealership: '',
      },
    };
  },
  methods: {
    isValidEmail(email) {
      return email != null;
    },
    async submit(event) {
      if (!event.target.form.reportValidity()) {
        this.$store.dispatch('buttonSubmitStore/done');
        return;
      }

      if (this.haveStarsData) {
        this.userObject.nameFirst = this.stars.nameFirst;
        this.userObject.nameLast = this.stars.nameLast;
      }

      const result = await this.$store.dispatch('userStore/createUser', this.userObject);
      this.$emit('savedUser', result);
      this.$store.dispatch('buttonSubmitStore/done');
    },
  },
  computed: {
    haveStarsData() {
      return this.stars !== null;
    },
    defaultPreferredName() {
      const firstName = this.stars !== null ? this.stars.nameFirst : '';
      const lastName = this.stars !== null ? this.stars.nameLast : '';
      return `${firstName} ${lastName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.loginForm {
    background-color: white;
    border: .5px solid black;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .required {
    color: $LincolnWarn;
  }

  .options {
    color: $LincolnGrayLight;
  }

  .textBox {
    margin: 1em;
    display: block;
  }

  button {
    border: none;
    margin: 0.1em;
    padding: 1em;
    text-transform: uppercase;
    background-color: $LincolnOrange;
    color: white;
    cursor: pointer;

    &.cancel {
      background-color: $LincolnGrayDark;
    }
}

.buttonOffset {
  margin: 1em;
}
</style>
