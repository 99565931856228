const fakeUsers = [
  {
    key: 'D18650A2-F10A-4101-85E7-5398014B96FF',
    value: 'Evan Musu',
  },
  {
    key: '76A525A1-ABF7-4CC9-9BBA-A95C7EE76C22',
    value: 'Jeff Reichel',
  },
  {
    key: '93D768DF-0F0F-4DC6-AB57-A4479D0D246D',
    value: 'John Giganti',
  },
  {
    key: '47D28522-8E89-4CFF-8233-02BDA543D9C3',
    value: 'Justin Nader',
  },
  {
    key: '1613F431-4B60-47EA-AD1E-055ABDDD74ED',
    value: 'Venkata Chilukuri',
  },
  {
    key: '25FF9A56-2CD6-4951-BDE7-BC352E78BCDD',
    value: 'Gareth Matson',
  },
  {
    key: '2ADCDC37-0C05-48F5-86A3-7819B02FF2AB',
    value: 'Brian Duffourc',
  },
  {
    key: '38D105C8-76A6-4826-8A27-46753A3BA19B',
    value: 'John Brzys',
  },
  {
    key: 'CDD1204A-19DA-47F6-8D93-D5CFCB3028CF',
    value: 'Mark Patrick',
  },
  {
    key: '3E8C782C-2811-43A2-B6EE-E896D033D9E6',
    value: 'Mark Patrick - Prod',
  },
];

export default fakeUsers;
