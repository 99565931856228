<template>
  <div class="loginReal">
    <LoadingOverlay v-show="showLoader" />
    <LoginSTARS
      v-show="showFormSTARS"
      @gotStars="gotStars"
      @noStars="noStars"
      :wslx="wslxObject"/>
    <LoginForm
      v-if="showForm"
      @savedUser="savedUser"
      :stars="starsObject"
      :wslx="wslxObject"/>
  </div>
</template>

<script>

import LoginForm from './loginForm.vue';
import LoginSTARS from './loginSTARS.vue';
import LoadingOverlay from '../loaders/LoadingOverlay.vue';
import { getSessionItem } from '../../utils';

export default {
  name: 'LoginReal',
  components: {
    LoginForm,
    LoginSTARS,
    LoadingOverlay,
  },
  data() {
    return {
      showForm: false,
      showFormSTARS: false,
      oAuthObject: null,
      wslxObject: null,
      starsObject: null,
      userObject: null,
      showLoader: false,
    };
  },
  async created() {
    this.showLoader = true;
    await this.oAuth();

    const result = await this.doesUserExist();

    if (result.status && result.status === 404) {
      this.showFormSTARS = true;
      this.showLoader = false;
    } else {
      this.login(result);
    }
  },
  methods: {
    gotStars(starsObject) {
      this.starsObject = starsObject;
      this.showForm = true;
      this.showFormSTARS = false;
    },
    noStars() {
      this.showForm = true;
      this.showFormSTARS = false;
    },
    savedUser(userObject) {
      this.showForm = false;
      this.login(userObject.id);
    },
    async doesUserExist() {
      const exists = await this.$store.dispatch('userStore/checkUser', this.wslxObject.cdsId);
      return exists;
    },
    async login(foundUserId) {
      this.showLoader = true;
      await this.$store.dispatch('userStore/login', { userId: foundUserId });
      await this.$store.dispatch('userStore/getUser', foundUserId);

      const analytic = {
        analyticType: 'LogOn',
        resourceId: null,
        userId: this.$store.getters['userStore/userId'],
      };
      this.$store.dispatch('analyticStore/add', analytic);

      this.showLoader = false;
      const directlink = getSessionItem('directlink');
      if (directlink && directlink !== '') {
        this.$router.push(directlink);
      } else {
        this.$router.push('/');
      }
    },
    async oAuth() {
      if (this.$route.query.code === undefined) {
        const url = await this.$store.dispatch('userStore/oAuthAuthorizeURL');
        window.location.replace(url);
      } else {
        this.oAuthObject = await this.$store.dispatch('userStore/oAuthToken', { authCode: this.$route.query.code });
        this.wslxObject = {
          cdsId: this.oAuthObject.uid,
          paCode: this.oAuthObject.fordSiteCode,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
