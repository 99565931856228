<template>
  <div class="starsID">
    <p>
      Welcome to the Effortless Guide!
      To get started, please enter your STARS ID and click the 'Lookup' button.
    </p>
    <form class="formSection"
          @submit.prevent="submit">
      <div class="title">STARS ID LOOKUP</div>
      <TextBox :label="'STARS ID'"
               :name="'starsID'"
               v-model="starsObject.starsID"
               class="textBox" />
      <div class="notFound">{{this.message}}</div>
      <ButtonSubmit
        class="buttonOffset"
        :copy="'Lookup'"
        @callback="submit"
      />
    </form>
    <button
      v-if="doNotHaveStarsId"
      @click="noStars"
      class="noStarBtn">
      Don't Have STARS ID, Click Here
      </button>
  </div>
</template>

<script>

import TextBox from '../formElements/TextBox.vue';
import ButtonSubmit from '../formElements/buttons/buttonSubmit.vue';

export default {
  name: 'LoginSTARS',
  props: ['wslx'],
  components: {
    TextBox,
    ButtonSubmit,
  },
  data() {
    return {
      message: '',
      starsObject: {
        starsID: '',
      },
    };
  },
  computed: {
    doNotHaveStarsId() {
      if (this.wslx === null) {
        return false;
      }

      if (this.wslx.paCode.match(/^[0-9]{5}$/)) {
        return false;
      }
      return true;
    },
  },
  methods: {
    async submit() {
      const starsResult = await this.$store.dispatch('userStore/getUserByStarsID', this.starsObject.starsID);

      if (starsResult.status && starsResult.status === 404) {
        this.message = starsResult.data;
      } else {
        this.$emit('gotStars', starsResult);
      }
      this.$store.dispatch('buttonSubmitStore/done');
    },
    noStars() {
      this.$emit('noStars');
    },
  },
};
</script>

<style lang="scss" scoped>
.starsID {
  background-color: white;

  .title {
    margin-left: 1em;
    text-transform: bold;
  }

  .textBox {
    margin: 1em;
    display: block;
  }

  .notFound {
      color: $LincolnOrange;
      padding-bottom: 10px;
  }

  .formSection {
    border: .5px solid black;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .buttonOffset {
    margin: 1em;
  }

  .buttonOffsetNoStars {
    margin-top: 1em;
  }

  .noStarBtn {
    margin-top: 45px;
    padding: 1em;
    border: .5px solid $LincolnOrange;
    background-color: white;
    color: black;
    cursor: pointer;

    &:hover {
      background-color: $LincolnGrayDark;
      color: white;
    }
  }
}
</style>
