<template>
  <div v-show="show" class="loadingOverlay">
    <div
      class="loadingContainer"
    >
      <font-awesome-icon
        :icon="['fas', 'cog']"
        size="2x"
        class="fa-spin loading"
      />
    </div>
  </div>
</template>

<script>


export default {
  name: 'LoadingOverlay',
  data() {
    return {
      show: true,
    };
  },
  created() {
    // Just a fail-safe if the parent component
    // doesn't properly close this component
    setTimeout(() => { this.show = false; }, 60000);
  },
};
</script>

<style lang="scss" scoped>

.loadingOverlay {
    width: 100%;
    height: 60px;
    position: relative;
    color: black;

    .loadingContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: absolute;

    .loading {
      z-index: 100;
      align-self: center;
    }
  }
}
</style>
