<template>
  <div class="loginFake">
    <div class="dropdown">
    <label>Select User:</label>
    <select v-model="value" @change="selectedUser" required>
        <option value="">Select User</option>
        <option v-for="(option, index) in fakeUsers"
        :key="index"
        :value="option">{{ option.value }}</option>
    </select>
</div>
  </div>
</template>

<script>

import dataFakeUsers from '../../data/fakeUsers';
import { getSessionItem } from '../../utils';

export default {
  name: 'LoginFake',
  data() {
    return {
      value: '',
      fakeUsers: dataFakeUsers,
    };
  },
  methods: {
    async selectedUser() {
      await this.$store.dispatch('userStore/login', { userId: this.value.key });
      await this.$store.dispatch('userStore/getUser', this.value.key);

      const analytic = {
        analyticType: 'LogOn',
        resourceId: null,
        userId: this.$store.getters['userStore/userId'],
      };
      this.$store.dispatch('analyticStore/add', analytic);

      const directlink = getSessionItem('directlink');
      if (directlink && directlink !== '') {
        this.$router.push(directlink);
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loginFake {
  margin-top: 20%;
  margin-bottom: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .dropdown {
    label {
      display: block;
      font-weight: bold;
      }
    }
}
</style>
